import bcrypt from 'bcryptjs';
import { AdsQueue } from './views/AdLoop/requests';

type QueueType = {
  current: AdsQueue | null
};

const serverUrl = process.env.REACT_APP_SERVER_URL;
const strapiUrl = process.env.REACT_APP_STRAPI_URL;
const businessUrl = process.env.REACT_APP_BUSINESS_URL;
const cdnURL = process.env.REACT_APP_CDN_URL;
const streetsApiKey = process.env.REACT_APP_STREETS_API_KEY;

const axiosConfig = {
  headers: {
    Authorization: `apiKey ${bcrypt.hashSync(streetsApiKey || '', 10)}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 60 * 1000,
};

const streetsUrl = `${serverUrl}/streets`;

const isProduction = process.env.REACT_APP_ENV === 'production';

const queues: QueueType = { current: null };
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const handleQueues = (newValue: any): void => {
  queues.current = newValue;
};

const directAdsBlacklist = [
  'ffa82bd3-601b-4003-9399-3c744068102c',
  'b176a07d-b697-4a0c-8c88-37d665ffb6f3',
  'fc2f3696-898a-4e14-9eb8-09706dda6a32',
  '87eab5fb-ff5a-43dc-a3e0-fe6aa999d9af',
  'b2ce77de-5434-44d3-a0aa-492be8f1bddb',
  '641fecf8-809c-412f-8f34-1a0f287afdcd',
  'c32e61db-4846-4031-ab01-50c88b6f9f5b',
  'b15c695c-201c-4873-b127-3708b713d44d',
  '8187f1f5-56cc-42fe-8b11-61574419fad9',
  'e24f5201-83e4-4d72-84b7-c8110a3ecd5e',
  'efe52c88-8954-4c43-ad66-fbf112a997b1',
  '019055a8-1c9f-7688-8d51-c8d6db31e6f9',
  '4cb7f87a-e97a-432a-8154-7e2f7b50c7e8',
  '6dc4e70d-875b-4612-a94b-6421d91271e4',
  '05e8d295-ec5c-4d04-a6aa-d16c0d59c164',
];

export {
  serverUrl, strapiUrl, businessUrl, streetsUrl, isProduction, cdnURL, streetsApiKey, axiosConfig, queues, handleQueues, directAdsBlacklist,
};
